import { GetActionPipe } from '@shared/pipes/get-action.pipe';
import { ListRendererWidgetTextStyleByConditionComponent } from './core/builders/list/list-renderer-widget-text-style-by-condition/list-renderer-widget-text-style-by-condition.component';
import { BrowserModule } from '@angular/platform-browser';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PermissionGuard, TranslationLoader } from '@capturum/complete';
import { CoreModule } from '@core/core.module';
import { ConfirmationService } from 'primeng/api';
import { LayoutModule } from '@shared/modules/layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInjector } from './app-injector.service';
import { CapturumToastModule } from '@capturum/ui/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumMissingTranslationHandler } from '@capturum/shared';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '@environments/environment';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { GeneralState } from '@store/general/general.state';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { ListBackendConfirmationService } from '@core/actions/list-backend-confirmation.service';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { PanelState } from '@store/panel/panel.state';
import { ListRendererWidgetCurrencyComponent } from '@core/builders/list/list-renderer-widget-currency/list-renderer-widget-currency.component';
import { ListRendererWidgetCheckboxComponent } from '@core/builders/list/list-renderer-widget-checkbox/list-renderer-widget-checkbox.component';
import { ListRendererWidgetInputComponent } from '@core/builders/list/list-renderer-widget-input/list-renderer-widget-input.component';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { ListDownloadService } from '@core/actions/list-download.service';
import { DialogCloseService } from '@core/actions/dialog-close.service';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogOpenService } from '@core/actions/dialog-open.service';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import { OpenSidebarService } from '@core/actions/open-sidebar.service';
import { ListRendererWidgetDropdownComponent } from '@core/builders/list/list-renderer-widget-dropdown/list-renderer-widget-dropdown.component';
import { ListBackendService } from '@core/actions/list-backend.service';
import { ListRendererWidgetMinusPrefixComponent } from './core/builders/list/list-renderer-widget-minus-prefix/list-renderer-widget-minus-prefix.component';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { ListRendererWidgetTextInputComponent } from '@core/builders/list/list-renderer-widget-text-input/list-renderer-widget-text-input.component';
import { DeMooijDialogService } from '@core/services/de-mooij-dialog-service.service';
import { TimeInputComponent } from '@core/builders/form/time-input/time-input.component';
import { PriceInputComponent } from '@core/builders/form/price-input/price-input.component';
import { CustomNavigationService } from '@core/actions/custom-navigation.service';
import { ListRendererWidgetStatusIconComponent } from '@core/builders/list/list-renderer-widget-status-icon/list-renderer-widget-status-icon.component';
import { ListRendererWidgetIntegerComponent } from '@core/builders/list/list-renderer-widget-integer/list-renderer-widget-integer.component';
import { AutocompleteCustomerComponent } from '@core/builders/form/autocomplete-customer/autocomplete-customer.component';
import { ProductMultiselectDropdownComponent } from '@core/builders/form/product-multiselect-dropdown/product-multiselect-dropdown.component';
import { CapturumLoaderModule } from '@capturum/ui/loader';
import { ListRendererWidgetDateComponent } from '@core/builders/list/list-renderer-widget-date/list-renderer-widget-date.component';
import { DateInputComponent } from '@core/builders/form/date-input/date-input.component';
import { SearchInputFilterComponent } from '@shared/components/search-input-filter/search-input-filter.component';
import { ListRendererWidgetBooleanComponent } from '@core/builders/list/list-renderer-widget-boolean/list-renderer-widget-boolean.component';
import { ListRendererWidgetToggleInputComponent } from '@core/builders/list/list-renderer-widget-toggle-input/list-renderer-widget-toggle-input.component';
import { ListRendererWidgetIconTooltipComponent } from '@core/builders/list/list-renderer-widget-icon-tooltip/list-renderer-widget-icon-tooltip.component';
import { ListRendererWidgetProgressIconComponent } from '@core/builders/list/list-renderer-widget-progress-icon/list-renderer-widget-progress-icon.component';
import { ListRendererWidgetMoveActionComponent } from '@core/builders/list/list-renderer-widget-move-action/list-renderer-widget-move-action.component';
import { ListRendererWidgetIconByConditionComponent } from '@core/builders/list/list-renderer-widget-icon-by-condition/list-renderer-widget-icon-by-condition.component';
import { ListRendererProductDescriptionResponsiveComponent } from '@core/builders/list/list-renderer-product-description-responsive/list-renderer-product-description-responsive.component';
import { SelectRowService } from '@core/actions/select-row.service';
import { ListRendererWidgetOrderTypeIconComponent } from '@core/builders/list/list-renderer-widget-order-type-icon/list-renderer-widget-order-type-icon.component';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CapturumMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    CoreModule,
    LayoutModule,
    CapturumToastModule,
    CapturumBuilderCoreModule.forRoot({
      actions: {
        [BuilderActionType.backendConfirmation]: ListBackendConfirmationService,
        [BuilderActionType.itemDownload]: ListDownloadService,
        [BuilderActionType.closePopup]: DialogCloseService,
        [BuilderActionType.openPopup]: DialogOpenService,
        [BuilderActionType.openSidebar]: OpenSidebarService,
        [BuilderActionType.backend]: ListBackendService,
        [BuilderActionType.customNavigation]: CustomNavigationService,
        [BuilderActionType.selectRow]: SelectRowService,
      },
    }),
    CapturumListRendererModule.forRoot({
      'list_wms-dashboard-orders': {
        actions: {
          'remove-dashboard-order': {
            hiddenExpression: (row: any) => {
              return row.order_progress === 1;
            },
          },
        },
      },
      'list_wms-picked-products': {
        styleClassExpression: (row) => {
          return row.last_stack_item ? 'last-stack-item' : '';
        },
      },
      'list_wms-order-picking': {
        styleClassExpression: (row) => {
          return row.stock_transfer ? 'stock-transfer' : '';
        },
      },
      filters: [
        {
          name: 'input',
          component: SearchInputFilterComponent,
        },
      ],
      widgets: {
        currency: ListRendererWidgetCurrencyComponent,
        checkbox: ListRendererWidgetCheckboxComponent,
        input: ListRendererWidgetInputComponent,
        dropdown: ListRendererWidgetDropdownComponent,
        'text-input': ListRendererWidgetTextInputComponent,
        'minus-prefix': ListRendererWidgetMinusPrefixComponent,
        'text-style-by-condition': ListRendererWidgetTextStyleByConditionComponent,
        'status-icon': ListRendererWidgetStatusIconComponent,
        integer: ListRendererWidgetIntegerComponent,
        'iso-date': ListRendererWidgetDateComponent,
        boolean: ListRendererWidgetBooleanComponent,
        'toggle-icon': ListRendererWidgetToggleInputComponent,
        'icon-tooltip': ListRendererWidgetIconTooltipComponent,
        'progress-icon': ListRendererWidgetProgressIconComponent,
        'move-action': ListRendererWidgetMoveActionComponent,
        'icon-by-condition': ListRendererWidgetIconByConditionComponent,
        'product-description-responsive': ListRendererProductDescriptionResponsiveComponent,
        'order-type-icon': ListRendererWidgetOrderTypeIconComponent,
      },
    }),
    CapturumFormRendererModule.forRoot({
      translationKeyPrefix: 'demooij',
      types: [
        {
          name: 'time-input',
          component: TimeInputComponent,
        },
        {
          name: 'date-input',
          component: DateInputComponent,
        },
        {
          name: 'price-input',
          component: PriceInputComponent,
        },
        {
          name: 'autocomplete-customer',
          component: AutocompleteCustomerComponent,
        },
        {
          name: 'product-multiselect-dropdown',
          component: ProductMultiselectDropdownComponent,
        },
      ],
    }),
    ConfirmDialogModule,
    CapturumButtonModule,
    NgxsModule.forRoot([GeneralState, PanelState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [GeneralState, PanelState, 'listRenderer', 'formRenderer', 'formBuilder'],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    DialogModule,
    CapturumLoaderModule,
  ],
  providers: [
    ConfirmationService,
    PermissionGuard,
    GetActionPipe,
    { provide: DialogService, useClass: DeMooijDialogService },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
