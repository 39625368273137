import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService, ApiSingleResult } from '@capturum/api';
import { CreditNote } from '@core/interfaces/credit-note.interface';
import {
  SplitQuantityProducts,
  TotalVolumeSplitQuantity,
} from '@features/sales-order/models/split-quantity-products.model';
import { SplitOrderData } from '@core/interfaces/spli-order-data.interface';
import { map } from 'rxjs/operators';
import { SalesOrder } from '@core/models/sales-order.model';
import { MapItem } from '@capturum/auth';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BaseKey } from '@core/enums/base-key.enum';
import { InputWidgetService } from '@core/services/input-widget.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { GeneralSelectors } from '@store/general/general.selectors';
import { CompanyCodesEnum } from '@core/enums/company-codes.enum';
import { Store } from '@ngxs/store';
import { IStoreProductsModel } from '@features/sales-order/models/store-products.model';

@Injectable({
  providedIn: 'root',
})
export class SalesOrderService {
  public salesOrder$: BehaviorSubject<SalesOrder> = new BehaviorSubject<SalesOrder>(null);
  public salesOrderStatuses$: BehaviorSubject<MapItem[]> = new BehaviorSubject<MapItem[]>([]);

  protected endpoint = 'sales-order';

  constructor(
    private readonly apiHttp: ApiHttpService,
    private inputWidgetService: InputWidgetService,
    private confirmationSvc: ConfirmationService,
    private translateService: TranslateService,
    private store: Store,
  ) {}

  public get salesOrderStatuses(): MapItem[] {
    return this.salesOrderStatuses$.getValue();
  }

  public get currentOrderStatusId(): string {
    return this.salesOrder$.getValue()?.status_base_data_value_id;
  }

  public get orderStatus(): BaseKey {
    return this.salesOrderStatuses.find(({ value }: MapItem) => {
      return value === this.currentOrderStatusId;
    })?.key as BaseKey;
  }

  public get statusToShowConfirmation(): boolean {
    return [BaseKey.SalesOrderStatusHandled, BaseKey.SalesOrderStatusReady].includes(this.orderStatus);
  }

  public get inProgressStatus(): MapItem {
    return this.salesOrderStatuses.find(({ key }: MapItem) => {
      return key === BaseKey.SalesOrderStatusInProgress;
    });
  }

  public get inProgressStatusId(): { status_base_data_value_id: any } {
    return {
      status_base_data_value_id: this.inProgressStatus?.value,
    };
  }

  public get salesOrderId(): string {
    return this.salesOrder$.getValue()?.id;
  }

  public get isCashCarryCompany(): boolean {
    return this.store.selectSnapshot(GeneralSelectors.getActiveCompany)?.code === CompanyCodesEnum.cashcarry;
  }

  public showConfirmPopup(submit: () => void): void {
    if (!this.statusToShowConfirmation || !this.isCashCarryCompany) {
      submit();

      return;
    }

    this.confirmationSvc.confirm({
      header: this.translateService.instant('confirm.title'),
      message: this.translateService.instant('demooij.confirmation.create-pick-order'),
      accept: () => {
        this.inputWidgetService.changeStatus(this.salesOrderId, this.inProgressStatusId).subscribe({
          next: () => {
            submit();
          },
        });
      },
      reject: () => {
        submit();
      },
    });
  }

  public postCreditnotePDF(salesOrderId: string, data: { products: CreditNote[] }): Observable<any> {
    return this.apiHttp.post(`/credit-note`, {
      sales_order_id: salesOrderId,
      ...data,
    });
  }

  public postPalletReturn(salesOrderId: string, data: { products: CreditNote[] }): Observable<any> {
    return this.apiHttp.post(`/pallet-return`, {
      sales_order_id: salesOrderId,
      ...data,
    });
  }

  public getTotalVolumes(salesOrderId: string, body: SplitQuantityProducts): Observable<TotalVolumeSplitQuantity> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/pre-calculate-split-order`, body);
  }

  public allocateAllProducts(salesOrderId: string): Observable<void> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/bulk-allocate`, {});
  }

  public removeAllAllocations(salesOrderId: string): Observable<void> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/remove-allocations`, {});
  }

  public getEdiImportErrors(salesOrderId: string): Observable<File> {
    return this.apiHttp.get(`/${this.endpoint}/${salesOrderId}/get-edi-import-errors`, {
      responseType: 'blob',
    });
  }

  public bulkStatusUpdate(body: Record<string, Date>): Observable<object> {
    return this.apiHttp.post(`/${this.endpoint}/bulk-status-update`, body);
  }

  public getTotal(salesOrderId: string): Observable<
    ApiSingleResult<{
      products: Record<string, string>;
      costs: Record<string, string>;
    }>
  > {
    return this.apiHttp.get(`/${this.endpoint}/${salesOrderId}/summary`);
  }

  public sendFiton(salesOrderId: string): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}/${salesOrderId}/fiton-export`);
  }

  public splitOrder(salesOrderId: string, products: Partial<SplitOrderData>[]): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/split-order`, {
      products,
    });
  }

  public bulkStoreProducts(id: string, data: IStoreProductsModel): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/${id}/bulk-store-products`, data);
  }

  public getSalesOrderById(id: string): Observable<SalesOrder> {
    return this.apiHttp.get<ApiSingleResult<SalesOrder>>(`/${this.endpoint}/${id}`).pipe(
      map((result) => {
        return result.data;
      }),
    );
  }

  public updatePrice(salesOrderId: string): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/update-prices `, {});
  }

  public sendToPrinter(salesOrderId: string, url: string): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}/${salesOrderId}/${url}`, {});
  }

  public downloadSalesOrders(url: string, body: { [key: string]: any }): Observable<Blob> {
    return this.apiHttp.post(url, body, { responseType: 'blob' });
  }

  public printCmr(url: string, body: { [key: string]: any }): Observable<any> {
    return this.apiHttp.post(url, body);
  }

  public removeNoQuantityProducts(salesOrderId: string): Observable<void> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/remove-products-no-quantity`, {});
  }

  public updatePriceAndStatus(): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/update-prices-and-status`, {});
  }
}
