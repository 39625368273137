import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { EntityDialogBaseComponent } from '../../../entity-base/components/entity-dialog-base/entity-dialog-base.component';
import { auditTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-entity-form-dialog',
  templateUrl: './entity-form-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityFormDialogComponent extends EntityDialogBaseComponent implements OnInit, AfterViewInit {
  public hasSubmitAndContinue: boolean;
  public hideSuccessfulToast: boolean;

  constructor(
    protected injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.hasSubmitAndContinue = this.config?.data?.hasSubmitAndContinue ?? true;
    this.formKey = this.config?.data?.key;
    this.entity = this.config?.data?.entity;
    this.entityFormKey = this.config?.data?.key;
    this.action = this.config?.data?.action;
    this.successfulMessage = this.config?.data?.successfulMessage;
    this.hideSuccessfulToast = this.config?.data?.hideSuccessfulToast;
  }

  public ngAfterViewInit(): void {
    this.formRenderer.form.valueChanges
      .pipe(
        auditTime(1000),
        tap(() => {
          this.formRenderer.form?.markAllAsTouched();
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }
}
