import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CustomerService } from '@features/customer/services/customer.service';
import { FieldType } from '@ngx-formly/core';
import { Observable, Subject, switchMap } from 'rxjs';
import { Customer } from '@store/public/public-state.model';
import { AutocompleteItem } from '@core/interfaces/autocomplete-item.interface';
import { filter, takeUntil } from 'rxjs/operators';
import { responseData } from '@capturum/builders/core';

@Component({
  selector: 'app-autocomplete-customer',
  templateUrl: './autocomplete-customer.component.html',
  styleUrls: ['./autocomplete-customer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutocompleteCustomerComponent extends FieldType implements AfterViewInit, OnDestroy {
  public results$: Observable<AutocompleteItem[]>;
  public autocompleteInputValue: AutocompleteItem = { key: '', label: '' };
  public showClearButton = false;
  public isSelected = false;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    public customerApiService: CustomerService,
    public cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();

    this.formControl?.valueChanges
      ?.pipe(
        filter((value) => {
          return Boolean(value);
        }),
        switchMap((value: string) => {
          return this.customerApiService.get(value);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((customer: Customer) => {
        this.autocompleteInputValue = {
          key: customer.id,
          label: customer.name,
        };

        this.showClearButton = true;

        this.cdr.detectChanges();
      });

    if (this.formControl.value) {
      this.formControl.setValue(this.formControl.value);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public clearAll(): void {
    this.autocompleteInputValue = { key: '', label: '' };

    this.formControl.setValue(null);
    this.formControl['defaultValue'] = null;

    this.showClearButton = false;
  }

  public search(event: { originalEvent: InputEvent; query: string }): void {
    this.showClearButton = true;
    this.isSelected = false;

    this.results$ = this.customerApiService
      .list({
        search: event?.query,
      })
      .pipe(responseData);
  }

  public customerSelected(event: AutocompleteItem): void {
    this.isSelected = true;
    this.formControl?.setValue(event['value']?.key);
  }

  public removeCustomer(): void {
    if (!this.isSelected) {
      this.formControl?.reset();
    }
  }
}
