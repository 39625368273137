import { GeneralSelectors } from '@store/general/general.selectors';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { PanelSelectors } from '@store/panel/panel.selectors';
import { Observable } from 'rxjs';
import { PanelConfig } from '@core/models/panel-config.model';
import { UpdateItemInPanel, SetPanelConfig } from '@store/panel/panel.actions';
import { DestroyBase } from '@capturum/shared';
import { delay, takeUntil, filter, map } from 'rxjs/operators';
import { ComponentType } from '@angular/cdk/overlay';
import { PanelComponentType } from '@core/enums/panel-component-type.enum';
import { DayPricePanelComponent } from '@features/day-price/components/day-price-panel/day-price-panel.component';
import { PanelComponentPrototype } from '@core/interfaces/panel-widget-prototype';
import { DetailsComponent } from '@shared/components/details/details.component';
import { DynamicComponentComponent } from '@shared/modules/layout/components/dynamic-component/dynamic-component.component';
import { SalesOrderProductPanelComponent } from '@features/sales-order/components/sales-order-product-panel/sales-order-product-panel.component';
import { NegativeStockPanelComponent } from '@features/purchase-order/components/negative-stock-panel/negative-stock-panel.component';
import { OpenSidebarService } from '@core/actions/open-sidebar.service';
import { Company } from '@core/models/company.model';
import { ProductAllocationDetailsPanelComponent } from '@shared/components/product-allocation-details-panel/product-allocation-details-panel.component';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent extends DestroyBase implements OnInit {
  @Select(PanelSelectors.getConfig) public config$: Observable<PanelConfig>;
  @Select(GeneralSelectors.getActiveCompany)
  public activeCompany$: Observable<Company>;

  @ViewChild(DynamicComponentComponent)
  public dynamicComponent: DynamicComponentComponent;

  public title: string;
  public config: PanelConfig;
  public isOpen: boolean;
  public components: Record<PanelComponentType, ComponentType<PanelComponentPrototype>> = {
    [PanelComponentType.dayPricePanel]: DayPricePanelComponent,
    [PanelComponentType.orderDetailsPanel]: DetailsComponent,
    [PanelComponentType.salesOrderProduct]: SalesOrderProductPanelComponent,
    [PanelComponentType.negativeStock]: NegativeStockPanelComponent,
    [PanelComponentType.allocateDetails]: ProductAllocationDetailsPanelComponent,
  };

  constructor(
    private readonly actions: Actions,
    private readonly store: Store,
    private readonly openSidebarService: OpenSidebarService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public close(): void {
    this.store.dispatch(new SetPanelConfig(null));
    this.store.dispatch(new UpdateItemInPanel(null, null));
    this.openSidebarService.updateSidebarOnNextRowTab = false;
    this.openSidebarService.isSidebarOpen = false;

    if (typeof this.dynamicComponent?.componentRef?.instance['onClose'] === 'function') {
      this.dynamicComponent?.componentRef?.instance?.onClose();
      this.openSidebarService.isSidebarClosedSubject.next(true);
    }
  }

  public ngOnInit(): void {
    this.actions
      .pipe(
        ofActionSuccessful(SetPanelConfig),
        map(({ config }) => {
          return config;
        }),
        filter((config) => {
          return this.configWasUpdated(config) && this.haveInputFocused;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((config) => {
        this.title = config?.title;
        this.isOpen = !!config;
        this.openSidebarService.isSidebarOpen = this.isOpen;
        this.cdr.detectChanges();
      });

    this.config$
      .pipe(
        filter((config) => {
          return this.configWasUpdated(config) && this.haveInputFocused;
        }),
        takeUntil(this.destroy$),
        delay(500),
      )
      .subscribe((config) => {
        this.title = config?.title;
        this.config = config;
        document?.body?.click();
      });

    this.activeCompany$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.close();
    });
  }

  private get haveInputFocused(): boolean {
    // don't open when the input are focused
    const index = this.store.selectSnapshot(GeneralSelectors.focusedRowIndex);

    return !Number.isInteger(index);
  }

  private configWasUpdated(config: PanelConfig): boolean {
    // don't rerender panel if use clicked the same row
    return JSON.stringify(config) !== JSON.stringify(this.config);
  }
}
